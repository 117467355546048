<template>
  <v-content>
    <div
      class="show-ambience"
      v-if="commonHelper.isDevelopmentEnvironment() || commonHelper.isReleaseEnvironment()"
    >
      <div v-if="commonHelper.isDevelopmentEnvironment()">AMBIENTE DE DESENVOLVIMENTO</div>
      <div v-else-if="commonHelper.isReleaseEnvironment()">AMBIENTE DE HOMOLOGAÇÃO</div>
    </div>
    <v-container fluid fill-height py-1 px-3>
      <v-layout align-center justify-center column w-100>
        <a @click="redirectLP">
          <FileImage :file="getLogo()" class="logo-campaign" :useBg="true" v-if="getLogo()!=null" />
          <div class="logo-login" v-else></div>
        </a>
        <LoginBox v-bind="$props" :inline="true"></LoginBox>
        <div class="login-copyright">
          <v-layout w-100>
            <div class="display-flex">
              Powered by
              <div class="ml-1">abraceumacausa.com.br</div>
            </div>
            <!-- <v-flex text-xs-right>
              <a href="javascript:;" @click="showServiceTerms = !showServiceTerms">Termos de uso</a>
              <a
                href="javascript:;"
                @click="showPolicyTerms = !showPolicyTerms"
              >Política de Privacidade</a>
            </v-flex>-->
          </v-layout>
        </div>
      </v-layout>
    </v-container>
    <FileImage :file="getBg()" :useBg="true" class="bg-campaign" v-if="getBg()!=null" />
    <div class="login-bg" v-else></div>
  </v-content>
</template>
<script type="plain/text">
import Container from "@/components/Container.vue";
import InputText from "@/components/input/InputText.vue";
import LoginService from "@/scripts/services/login.service";
import PersonService from "@/scripts/services/person.service";
import FacebookService from "@/scripts/services/facebook.service";
import GoogleService from "@/scripts/services/google.service";
import UserService from "@/scripts/services/user.service";
import Person from "@/scripts/models/person.model";
import Username from "@/scripts/models/username.model";
import UsernameType from "@/scripts/models/enums/usernameType.enum";
import InputPassword from "@/components/input/InputPassword.vue";
import LoginBox from "@/components/login/LoginBox.vue";
import { debug } from "util";
import CommonHelper from "@/scripts/helpers/common.helper";
import { Routes } from "@/scripts/models/enums/routes.enum";
import CampaignService from "@/scripts/services/campaign.service";
import { mapGetters } from "vuex";
import CampaignLandingPageOptions from "@/scripts/models/campaignLandingPageOptions.model";
import FileImage from "@/components/FileImage.vue";
import TypeCampaign from "@/scripts/models/enums/typeCampaign.enum";

export default {
  components: { Container, InputText, InputPassword, FileImage, LoginBox },
  props: ["action", "email", "facebookId", "googleId", "name", "campaign"],
  data() {
    return {
      campaignService: new CampaignService(),
      landingPageOptions: new CampaignLandingPageOptions(),
      commonHelper: CommonHelper
    };
  },
  created() {
    this.campaignService.getCampaignLandingPageConfigBySubdomain().then(
      function(data) {
        this.landingPageOptions = data;
      }.bind(this)
    );
  },
  methods: {
    redirectLP: function() {
      this.$router.push({ name: Routes.app.CampaignLandingPage });
    },
    getLogo() {
      if (
        this.landingPageOptions != null &&
        this.landingPageOptions.campaign != null &&
        this.landingPageOptions.campaign.file != null
      ) {
        return this.landingPageOptions.campaign.file;
      }
      return null;
    },
    getBg() {
      if (
        this.landingPageOptions != null &&
        this.landingPageOptions.heroFile != null
      ) {
        return this.landingPageOptions.heroFile;
      }
      return null;
    }
  }
};
</script>
