<template>
  <LoginUnique v-bind="$props" :campaign="campaign"></LoginUnique>
</template>
<script type="plain/text">
import LoginUnique from "@/views/web/login/LoginUnique.vue";
import CampaignService from "@/scripts/services/campaign.service.js";
import TypeCampaign from "@/scripts/models/enums/typeCampaign.enum";

export default {
  components: { LoginUnique, CampaignService },
  props: ["action", "email", "facebookId", "googleId", "name"],
  data() {
    return {
      publicCampaign: true,
      campaignService: new CampaignService(),
      loaded: false,
      campaign: null
    };
  },
  created() {
    this.campaignService.findBySubdomain().then(this.onCampaignFound);
  },
  methods: {
    onCampaignFound(campaign) {
      this.campaign = campaign;
      this.loaded = true;
    }
  }
};
</script>
